import { newIntegrationsService } from 'src/features/integrations/data-service/new-integrations-service';
import MsDeviceComplianceService, {
  msDeviceComplianceService,
} from '../../../ms-device-compliance/services';

// TODO: uncomment when functional is ready
/* istanbul ignore file */
import kandjiLogo from '../../../ms-device-compliance/assets/kandji-logo.svg';
import userGroup from '../../../ms-device-compliance/assets/user-group.svg';

import { links } from '../../../../../../app/common/constants';
import { INT_TYPES } from '../../../../constants';

import { i18n } from 'i18n';
import type { CallBackFunc } from '../../types/integrator.types';
import type {
  MsDeviceComplianceInputs,
  MsDeviceComplianceState,
} from '../../types/ms-device-compliance.types';

type MsDeviceComplianceCallBack = CallBackFunc<
  MsDeviceComplianceInputs,
  MsDeviceComplianceState
>;

export const handleInitialLoad: MsDeviceComplianceCallBack = ({
  nextPage,
  displayToast,
}) => {
  const searchParams = new URLSearchParams(window.location.search);
  const isCompleted = searchParams.get('success')?.toLowerCase();

  // redirect with param once the authentication is complete
  switch (isCompleted) {
    case 'true':
      // move to the 3d page
      nextPage();
      nextPage();
      break;
    case 'false': {
      // move to the 2d page
      nextPage();

      const message = searchParams.get('message') || i18n.error.generic();

      displayToast({
        title: message.replace(/'/g, ''),
        variant: 'error',
      });
      break;
    }
    default:
      break;
  }
};

export const handleSignIn: MsDeviceComplianceCallBack = ({
  formInputs,
  displayToast,
}) =>
  msDeviceComplianceService
    .getAdminConsentRedirectUrl(formInputs?.msPrimaryDomain)
    .then((res) => {
      if (res.success) {
        window.location.assign(res.data.redirect_url);
      } else {
        displayToast({
          title: 'Unable to obtain token from Microsoft',
          content: 'Authentication failed. Please try again',
          variant: 'error',
        });
      }
    })
    .catch(() => {
      displayToast({
        title: 'Unable to obtain token from Microsoft',
        content: 'Authentication failed. Please try again',
        variant: 'error',
      });
    });

const getIntegrationUUID = async () => {
  const response = await newIntegrationsService.listIntegrations(
    INT_TYPES.msDeviceCompliance,
  );
  return response?.data?.results[0]?.uuid;
};

export const handleSetGroupPolicies: MsDeviceComplianceCallBack = async ({
  formInputs,
  history,
  displayToast,
  setState,
}) => {
  setState({ isProcessing: true });
  displayToast({
    title: 'Setting group policies',
    content: 'In progress',
    variant: 'progress',
  });

  // format: id|external_id
  const platformGroupPolicies = {
    mac_os_devices_enabled: formInputs.macOsDevices || false,
    mac_os_group_ids: MsDeviceComplianceService.collectExternalIds(
      formInputs.macOsDevices_groups,
    ),
    ios_devices_enabled: formInputs.iosDevices || false,
    ios_group_ids: MsDeviceComplianceService.collectExternalIds(
      formInputs.iosDevices_groups,
    ),
  };

  const integrationId = await getIntegrationUUID();
  if (!integrationId) {
    displayToast({
      title: i18n.error.generic(),
      content: 'Please check the fields above and try again.',
      variant: 'error',
    });
    return setState({ isProcessing: false });
  }

  await msDeviceComplianceService
    .setGroupPolicies(integrationId, platformGroupPolicies)
    .then((res) => {
      if (res.success) {
        return history.push(
          `${links.integrations.root}/${INT_TYPES.msDeviceCompliance}`,
          { isConfirmed: true },
        );
      }
      displayToast({
        title: i18n.error.generic(),
        content: 'Please check the fields above and try again.',
        variant: 'error',
      });
    })
    .catch((e) => {
      displayToast({
        title: e.message,
        content: 'Please check the fields above and try again.',
        variant: 'error',
      });
    })
    .finally(() => {
      setState({ isProcessing: false });
    });
};

// Tickets
export const configTickets = [
  {
    title: 'Select Kandji as approved compliance partner',
    text: 'Before starting the integration in Kandji, make sure to select Kandji as a compliance partner in Intune for macOS, iOS, or iPadOS.',
    icon: kandjiLogo,
  },
  {
    title: 'Select user groups',
    text: `Select the appropriate Microsoft Entra ID user group(s) for which compliance data sent by Kandji will be applied. 
    (Be sure to not select the "Add all users" option. This is a special case and is not compatible with this integration.)`,
    icon: userGroup,
  },
];
